// @flow
import * as React from 'react';
import classnames from 'classnames';
import frameSrc from '../assets/images/screenshot-frame.svg';

import './screenshot-frame.less';
import Image from './image';
import * as analytics from '../utility/analytcs';

type Props = {
  imgSrc: string,
  movSrc: string,
  title: string,
  width: number,
  height: number,
};

type State = {
  isPlaying: boolean,
  showPoster: boolean,
};

class ScreenshotFrame extends React.Component<Props, State> {
  videoRef: { current: null | HTMLVideoElement } = React.createRef();
  state: State = {
    isPlaying: false,
    showPoster: true,
  };

  async play() {
    const { current: video } = this.videoRef;
    if (!video) {
      return;
    }

    if (video.paused) {
      analytics.trackEvent('Gameplay Frame', 'click', 'play');
      await video.play();
    } else {
      analytics.trackEvent('Gameplay Frame', 'click', 'pause');
      video.pause();
    }
  }

  handlePlayVideo = () => {
    this.setState({ isPlaying: true, showPoster: false });
  };

  handlePauseVideo = () => {
    this.setState({ isPlaying: false });
  };

  handleEndedVideo = () => {
    analytics.trackEvent('Gameplay Frame', 'finish');
  };

  componentWillUnmount() {
    const { current: video } = this.videoRef;
    if (!video) {
      return;
    }

    video.removeEventListener('play', this.handlePlayVideo);
    video.removeEventListener('pause', this.handlePauseVideo);
  }

  componentDidMount() {
    const { current: video } = this.videoRef;
    if (!video) {
      return;
    }

    video.addEventListener('play', this.handlePlayVideo);
    video.addEventListener('pause', this.handlePauseVideo);
  }

  render() {
    const { imgSrc, movSrc, title, width, height } = this.props;
    const { isPlaying, showPoster } = this.state;
    return (
      <div className={classnames('screenshot-frame', { 'screenshot-frame--playing': isPlaying })}>
        <div className="screenshot-frame__content-wrapper">
          {showPoster && (
            <div className="screenshot-frame__poster">
              <Image src={imgSrc} alt={title}/>
            </div>
          )}
          <video ref={this.videoRef}
                 onPlay={this.handlePlayVideo}
                 onPause={this.handlePauseVideo}
                 onEnded={this.handleEndedVideo}
                 autoPlay
                 muted
                 width={width}
                 height={height}
                 controls={null}
                 className="screenshot-frame__content">
            <source src={movSrc} type="video/mp4"/>
          </video>
        </div>
        <Image className="screenshot-frame__frame" src={frameSrc} aria-hidden={true} alt=""/>
        <div className="screenshot-frame__play">
          <button onClick={this.play.bind(this)} title={isPlaying ? 'Pause Video' : 'Play Video'}>
            <svg width="100%" height="100%" viewBox="0 0 40 64" xmlns="http://www.w3.org/2000/svg">
              <path d="M40,32l-52,32l0,-64l52,32Z" className="play"/>
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default ScreenshotFrame;
