// @flow
import * as React from 'react';
import Row from '../components/grid/row';
import Column from '../components/grid/column';
import iconSrc from '../assets/images/icon.svg';
import Container from '../components/grid/container';
import DefaultLayout from '../layouts/default';
import Image from '../components/image';
import SubscribeForm from '../partials/subscribe-form';
import ScreenshotFrame from '../components/screenshot-frame';
import { graphql } from 'gatsby';

/* eslint-disable jsx-a11y/accessible-emoji */

type Props = {
  data: {
    posterImage: {
      childImageSharp: {
        fixed: Object,
      },
    },
  },
};

export default ({ data: { posterImage: { childImageSharp } } }: Props) => (
  <DefaultLayout>
    <Container>
      <Row spaced={2}>
        <Column center>
          <ScreenshotFrame
            title="Gameplay Screenshot"
            movSrc={'/video/gameplay.mp4'}
            imgSrc={childImageSharp.fixed}
            width={1920}
            height={886}
          />
        </Column>
      </Row>
      <Row spaced={2} vCenter>
        <Column flex={4} className="hidden-md">
          <Image keepSmall rounded src={iconSrc} alt="Platform pixels icon"/>
        </Column>
        <Column flex={8}>
          <h2>Beta Coming Soon! 🚀</h2>
          <SubscribeForm fullWidth>
            Join Beta List
          </SubscribeForm>
        </Column>
      </Row>
      <Row spaced={2}>
        <Column>
          <h2 className="h3">Challenging 🙀</h2>
          <p>
            Prepare to have your hand-eye coordination put to the test with difficult levels that
            will have your palms sweating 💦
          </p>
        </Column>
        <Column>
          <h2 className="h3">X-Platform 📱💻</h2>
          <p>
            Early versions will be launching on iOS and Android, but expect a desktop version in the future 🚀
          </p>
        </Column>
        <Column>
          <h2 className="h3">Awesome Controls</h2>
          <p>
            The innovative on-screen joystick follows your finger position so you never accidentally lose it 🕹
          </p>
        </Column>
      </Row>
    </Container>
  </DefaultLayout>
);

export const pageQuery = graphql`
    query {
        posterImage: file(relativePath: {eq: "gameplay-poster.png"}) {
            childImageSharp {
                fixed(width: 960, height: 443) {
                    ...GatsbyImageSharpFixed
                }
            }
            relativePath
            sourceInstanceName
        }
    }
`;
